import * as React from "react"
import { Col, Container, Row } from "../Grid/Grid"
import { ImageProps } from "../Img/Img"
import PreviewSafeBackgroundImage from "../PreviewSafeBackgroundImage"
import Link, { LinkProps } from "../Link/Link"
import { styled } from "../../theme/theme"

interface CalloutProps {
  image?: null | ImageProps,
  text?: string,
  link?: LinkProps,
}

const StyledContent = styled(Col)`
text-align: center;
padding: 80px 0;
h2, h3, h4{
  font-weight: bold;
  padding-bottom: 40px;
  margin-bottom: 50px;
  border-bottom: 3px solid #798C4B;
}
`

export default function CalloutBg(props: CalloutProps) {
  const { image = null, text = "", link = null } = props

  return (
    <PreviewSafeBackgroundImage image={image} style={{backgroundPosition: "top center"}}>
      <Container>
        <Row>
          <StyledContent col={12} md={8} mdOffset={2}>
            {text}
            {link !== null &&
            <Link {...link} button={true}/>
            }
          </StyledContent>
        </Row>
      </Container>
    </PreviewSafeBackgroundImage>
  )
} 