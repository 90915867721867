import * as React from 'react'
import { graphql } from "gatsby"
import BulletPoints from "../../components/BulletPoints/BulletPoints"

export default (props) => {
  const {imageSharp, image, fields, heading = ''} = props

  const imageData = image && {
    imageInfo: typeof imageSharp === 'object' ? imageSharp : {src: image.url},
    alt: image.alt,
  }

  return (
    <BulletPoints image={imageData} points={fields} heading={heading} />
  )
}

export const query = graphql`
    fragment BulletPoints on PRISMIC_PageBodyBullet_points {
        type
        label
        primary {
            heading
            image
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 750) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        fields {
            point
        }
    }
`

