import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import Text from "../../components/Text/Text"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { graphql } from 'gatsby';

export default (props) =>
  (
    <Text alignment={props.alignment} isDark={props.dark_background} downArrow={props.down_arrow}>
      {RichText.render(props.bodyText, linkResolver, htmlSerializer)}
    </Text>
  )

export const query = graphql`
    fragment Text on PRISMIC_PageBodyText {
        type
        label
        primary{
            bodyText: text
            alignment
            dark_background
            down_arrow
        }
    }
`
