import * as React from "react"
import { prismicLinkMaker } from "../utils/linkResolver"
import { graphql } from "gatsby"
import Link from "../../components/Link/Link"
import { Col, Container, Row } from "../../components/Grid/Grid"
import { styled } from "../../theme/theme"
import uuidv4 from "../utils/uuidv4"

const StyledSection = styled(Col)`
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 28px;
  padding-bottom: 28px;
  h4{
    margin-bottom: 24px;
  }
`
const StyledCol = styled(Col)`
  margin-bottom: 10px;
  
  a{
    font-size: 1.25rem;
    text-decoration: underline;
    color: #000;
  }
`

export default (props) => {
  const { fields, heading } = props

  return (
    <Container>
      <Row>
        <StyledSection col={12} md={12} lg={8} lgOffset={2}>
          <h4>{heading}</h4>
          <Row>
            {fields.map(({ name, document }) => {
              const link = prismicLinkMaker({
                button_url: document,
                button_text: name,
              })

              return (
                <StyledCol key={uuidv4()} col={12} md={6}>
                  <Link {...link}>
                    <img style={{marginRight: 8}} src={'/images/download-icon.svg'} alt={'download icon'} />
                    {name}
                  </Link>
                </StyledCol>
              )
            })}
          </Row>
        </StyledSection>
      </Row>
    </Container>
  )
}

export const query = graphql`
    fragment DataSheets on PRISMIC_PageBodyData_sheets {
        type
        label
        fields{
            name
            document{
                ... Linkable
            }
        }
        primary{
            heading
        }
    }
`
