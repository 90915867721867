import * as React from "react"
import {ImageProps} from "../Img/Img"
import Link, {LinkProps} from "../Link/Link"
import PreviewSafeBackgroundImage from "../PreviewSafeBackgroundImage"
import {styled} from "../../theme/theme"
import {defaultMargins, my} from "../../utils/spacing"
import {
    Col,
    Container,
    Row
} from "../Grid/Grid";
import { media } from "styled-bootstrap-grid"

export interface ProductCalloutProps {
    children?: React.ReactNode,
    productImage?: null | ImageProps,
    sideImage?: null | ImageProps,
    heading?: string,
    subtitle?: string,
    className?: string,
    link?: LinkProps,
}

const StyledWrapper = styled(Container)`
  ${my(defaultMargins)};
  text-align: left;
`

export const StyledHeading = styled.h2`
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 20px;
    ${media.md`
    padding-bottom: 40px;
    margin-bottom: 50px;
    `}
    
    border-bottom: 3px solid #798C4B;
    
`

const StyledBody = styled.p`
    line-height: 150%;
`

const StyledInfoPanel = styled(Col)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #f1f1f1;
  padding: 50px;
  border-bottom: 8px solid ${props => props.theme.primaryColor};
  z-index: 9;
  position: relative;
  
  .info-panel__inner{
    ${media.md`
        max-width: calc(100% - 150px);
    `}
  }
`

const StyledBackgroundImage = styled(PreviewSafeBackgroundImage)`
  width: 100%;
  padding-bottom: 90%;
`

const StyledProductImage = styled(PreviewSafeBackgroundImage)`
  display: none;
  width: 400px;
  height: 400px;
  position: absolute !important;
  right: -100px;
  top: 0;
  ${media.md`
    display: block;
  `}
`

const StyledMobileProductImage = styled(PreviewSafeBackgroundImage)`
  width: 100px;
  height: 100px;
  margin-right: 0.5rem;
  margin-left: -2rem;
  ${media.md`
    display: none;
  `}
`

const StyledOffsetImage = styled(Col)`
  margin-bottom: -1rem;
  ${media.md`
    margin-top: 100px;
    margin-left: calc(100%/-12 - 16px);
    margin-bottom: 0;  
  `}
`

const StyledLink = styled(Link)`
  
`

function ProductCallout(props: ProductCalloutProps) {
    const {
        productImage = null,
        sideImage = null,
        className = '',
        heading,
        subtitle,
        link = null
    } = props

    return (
        <StyledWrapper className={className}>
            <Row style={{position: 'relative', alignItems: 'flex-start'}}>
                <StyledInfoPanel md={6} mdOffset={1} order={2} mdOrder={1}>
                    <StyledHeading>
                        {productImage &&
                        <StyledMobileProductImage image={productImage}/>
                        }
                        {heading}
                    </StyledHeading>
                    <div className="info-panel__inner">
                        <StyledBody>{subtitle}</StyledBody>
                        <StyledLink {...link} button={true}/>
                    </div>
                    {productImage && 
                    <StyledProductImage image={productImage}/>
                    }
                </StyledInfoPanel>
                {sideImage && 
                <StyledOffsetImage md={5} order={1} mdOrder={2}>
                    <StyledBackgroundImage image={sideImage}/>
                </StyledOffsetImage>
                }
            </Row>
        </StyledWrapper>
    )
}

export default ProductCallout;