import * as React from 'react'
import Hero from "../../components/Hero/Hero"
import { graphql } from "gatsby";
import { prismicLinkMaker } from "../utils/linkResolver"

export default (props) => {
  const { button_url, button_text, imageSharp, image, text_color, media } = props

  const link = prismicLinkMaker({button_url, button_text})

  return (
    <Hero
      {...props}
      link={link}
      image={{ alt: image.alt, imageInfo: imageSharp }}
      backgroundSize={'cover'}
      overlayGradient={null}
      textColor={text_color}
      videoUrl={media?.url}
    />
  )
}

export const query = graphql`
    fragment Hero on PRISMIC_PageBodyHero {
        type
        label
        primary {
            button_text
            button_url {
                ...Linkable
            }
            subtitle
            heading
            text_color
            media{
               ... on PRISMIC__FileLink{
                   url
               } 
            }
            image
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 2880) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`