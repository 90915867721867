import * as React from "react"
import GatsbyImg, { GatsbyImageProps } from 'gatsby-image'

export interface ImageProps {
  imageInfo: {
    childImageSharp?: null | GatsbyImageProps,
    src?: null | string
  },
  alt: string,
  style?: object,
  className?: string,
}

const Img = ({ imageInfo = {}, style = {}, alt, className = "" }: ImageProps) => {
  const { childImageSharp = null, src = null } = imageInfo;
  
  if (!!childImageSharp && !!childImageSharp.fixed) {
    return (
      <GatsbyImg className={className} style={style} fixed={childImageSharp.fixed} alt={alt} />
    )
  }

  if (!!childImageSharp && !!childImageSharp.fluid) {
    return <GatsbyImg className={className} style={style} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!src && typeof src === 'string')
    return <img className={className} style={style} src={src} alt={alt} />

  return null
}

export default Img