import * as React from "react"
import { styled } from "../../theme/theme"
import { Col, Container, Row } from "../Grid/Grid"
import { ImageProps } from "../Img/Img"
import { pl, pr, px, py } from "../../utils/spacing"
import PreviewSafeBackgroundImage from "../PreviewSafeBackgroundImage"
import uuidv4 from "../../prismic/utils/uuidv4"
import { media } from "styled-bootstrap-grid"

interface BulletPointProps {
  image?: null | ImageProps,
  heading?: string,
  points?: {point: string}[],
}

const StyledInner = styled.div<BulletPointProps>`
  background-color: #000;
  color: #fff;
  h2{
    color: #fff;
    border: none;
  }
  ${py({ default: 2, md: 5 })};
  ${pl({ default: 3, md: 6 })};
  ${pr(3)};
`

const StyledList = styled.ul<BulletPointProps>`
  margin: 0;
  padding: 0;
  list-style: none;
  li{
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    line-height: 100%;
    margin-bottom: 1.25rem;
    
    &:last-child{
    margin-bottom: 0;
    }
    
    &:before {
      content: '';
      display: inline-block;
      height: 1.5rem;
      width: 1.5rem;
      background-image: url(/images/check-bullet.svg);
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 1rem;   
    }
  }
`

const StyledImage = styled(PreviewSafeBackgroundImage)`
  height: 200px;
  ${media.md`
    height: 100%;
  `}
`

const BulletPoints = ({ image, points, heading }: BulletPointProps) => (
  <Container fluid>
    <Row style={{marginRight: -24, marginLeft: -24}}>
      <Col col={12} md={8} lg={6} order={2} mdOrder={1}>
        <StyledInner>
        <h2 style={{ marginBottom: "1.5rem" }}>{heading}</h2>
        <StyledList>
          {points.map(({point}) => <li key={uuidv4()}>{point}</li>)}
        </StyledList>
        </StyledInner>
      </Col>
      <Col col={12} md={4} lg={6} order={1} mdOrder={2}>
        <StyledImage image={image}/>
      </Col>
    </Row>
  </Container>
)

export default BulletPoints