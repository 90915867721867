/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, StaticQuery } from "gatsby"

import theme from "../theme/theme"
import { GridThemeProvider, BaseCSS } from "styled-bootstrap-grid"
import { ThemeProvider } from "styled-components"
import { withPreview } from "gatsby-source-prismic-graphql"
import { GlobalStyles } from '../theme/global';
import Helmet from "react-helmet"
import { PrismicNavigation } from "./PrismicNavigation"
import Footer from "../components/Footer/Footer"
import HubspotForm from "../components/Forms/HubspotForm"
import PrismicSpacer from "./components/PrismicSpacer"

const Layout = ({ children }) => {
  const query = graphql`
      query SiteTitleQuery {
          site {
              id
              siteMetadata {
                  title
                  description
              }
          }
      }
  `

  return (
    <StaticQuery
      query={`${query}`}
      render={withPreview(data => (
        <ThemeProvider theme={theme}>
          <GridThemeProvider gridTheme={theme}>
            <React.Fragment>
              <GlobalStyles/>
              <Helmet titleTemplate={`%s | ${data.site.siteMetadata.title}`}>
                <meta name="description" content={data.site.siteMetadata.description} />
                <link href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,800&text=%E2%84%A2%C2%AE" rel="stylesheet" />
              </Helmet>
              <BaseCSS/>
              <PrismicNavigation/>
              
              {children}
              <Footer />
            </React.Fragment>
          </GridThemeProvider>
        </ThemeProvider>
      ), query)}
    />
  )
}

export default Layout
