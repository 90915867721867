import * as React from "react"
import { media } from "styled-bootstrap-grid"
import { ImageProps } from "../Img/Img"
import TGILink, { LinkProps } from "../Link/Link"
import PreviewSafeBackgroundImage from "../PreviewSafeBackgroundImage"
import { styled } from "../../theme/theme"
import { mb, py } from "../../utils/spacing"
import { BackgroundImageProperty, BackgroundSizeProperty } from "csstype"
import { Col, Container, Row } from "../Grid/Grid"
import { opacify, transparentize } from "polished"

export interface HeroProps {
  children?: React.ReactNode,
  image?: null | ImageProps,
  videoUrl?: string,
  heading?: string,
  subtitle?: string,
  className?: string,
  backgroundSize?: BackgroundSizeProperty<any>,
  link?: LinkProps,
  overlayGradient?: BackgroundImageProperty
  textColor: 'Dark' | 'Light';
}

const StyledInner = styled(Col)`
  ${py({default: 3, sm: 6})};
`

const StyledHeading = styled.div`
    font-family: ${props => props.theme.headingFontFamily};
    font-style: normal;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 100%;
    margin-bottom: 15px;
    color: ${props => props.textColor === 'Dark' ? '#000' : '#fff'} !important;
    
    ${media.sm`
      font-size: 60px;
    `}
`

const StyledBody = styled.p`
    line-height: 122%;
    font-size: 36px;
    color: ${props => props.textColor === 'Dark' ? '#000' : '#fff'} !important;
    @media(max-width: 600px){
      font-size: 28px;
    }
    ${mb(2)};
    
`

const StyledBackgroundImage = styled(PreviewSafeBackgroundImage)`
  position: relative;
  background-position: bottom right;
  background-size: ${props => props.backgroundSize};
`

const StyledContainer = styled(Container)`
  position:relative;
    width: 100%;
    display: block;
    z-index: 9;
    background-color: ${props => transparentize(0.3, props.theme.secondaryColor)};
    ${media.md`
      background-color: transparent;
    `}
`

const StyledLink = styled(TGILink)`
  &.btn-outline{
        color: #fff;
        background-color: transparent;
        border: 1px solid #fff;
        text-transform: uppercase;
        
        &:hover{
          background-color: #fff;
          color: ${props => props.theme.primaryColor};
          text-decoration: none;
        }
      }
  }
`

const StyledWrapper = styled.div`
  margin-bottom: 0;
  position: relative;
  
  .pattern-overlay { 
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 1;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='1' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 3px;
    /*background-blend-mode: multiply;*/
    z-index: 1;
  }
  
  video { 
    object-fit: cover;
    object-position: center;
    width: 100%;
    position: absolute;
    height: 100%;
  }
  
  ${StyledHeading}{
    color: ${props => props.lightBackground ? '#000' : '#fff'};
  }
  
  ${StyledBody}{
    color: ${props => props.lightBackground ? '#000' : '#fff'};
  }
`


function Hero(props: HeroProps) {
  const {
    image = null,
    className,
    backgroundSize = "cover",
    overlayGradient = "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6))",
    heading,
    subtitle,
    textColor,
    link,
    lightBackground = true,
    videoUrl,
  } = props

  return (
    <StyledWrapper hasVideo={videoUrl} lightBackground={lightBackground} className={className}>
      <StyledBackgroundImage backgroundSize={backgroundSize} image={image}>
        {!videoUrl && overlayGradient &&
        <div style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundImage: overlayGradient,
        }}/>
        }
        {videoUrl && <div className="pattern-overlay"></div>}
        {videoUrl &&
        <video className="fullscreen" autoPlay muted loop>
          <source src={videoUrl} type='video/mp4;'/>
          {/*<source src="pour-out.mp4" type='video/mp4;'/>*/}
        </video>  
        }
        <StyledContainer fluid>
          <Container>
            <Row>
              <StyledInner col={12} md={8}>
                <StyledHeading hasVideo={videoUrl} textColor={textColor}><span>{heading}</span></StyledHeading>
                <StyledBody textColor={textColor}><span>{subtitle}</span></StyledBody>
                  {!!link && <StyledLink {...link}  button={true} outline={!lightBackground}/>}
              </StyledInner>
            </Row>
          </Container>
        </StyledContainer>
      </StyledBackgroundImage>
    </StyledWrapper>
  )
}

Hero.styled = {
  Heading: StyledHeading,
  Body: StyledBody,
  Link: StyledLink,
  Inner: StyledInner,
  BackgroundImage: StyledBackgroundImage,
  Container: StyledContainer,
}

export default Hero