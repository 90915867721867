import * as React from "react"
import { Col, Row } from "../Grid/Grid"
import Img, { ImageProps } from "../Img/Img"
import { styled } from "../../theme/theme"
import { mb, mt } from "../../utils/spacing"

export interface TestimonialProps {
  text: React.ReactNode,
  fullName: string,
  personTitle: string,
  className?: string,
}

const StyledRow = styled(Row)`
  align-items: center;
`

const StyledCol = styled(Col)`
${mt({default: 2, md: 0})}
text-align: center;
h2{
  ${mb({default: 3, sm: 5})}
}
.name{
  font-weight: bold;
  font-size: 18px;
  line-height: 1.75rem;
  
}
.title{
  font-size: 1rem;
  line-height: 120%;
}
`

const StyledBack = styled.div`
background-image: url(/images/faded-quotation-marks.svg);
background-size: contain;
background-repeat: no-repeat;
`

const Testimonial = ({ text, fullName, personTitle, className = "" }: TestimonialProps) => (
  <StyledRow>
    <StyledCol col={12} md={6} mdOffset={3}>
      <h2><span>What People Are Saying</span></h2>
      <StyledBack>
      {text}
      <div>
        <div className={"name"}>{fullName}</div>
        <div className={"title"}>{personTitle}</div>
      </div>
      </StyledBack>
    </StyledCol>
  </StyledRow>
)

export default Testimonial