import { navigate } from "gatsby-link"
import * as React from "react"
import { Container } from "styled-bootstrap-grid"
import { styled } from "../../theme/theme"
import GridRenderer from "../GridRenderer/GridRenderer"
import Submit from "../Link/Submit"
import DownArrow from "../DownArrow"

const StyledFormContainer = styled(Container)`
  background-color: ${props => props.theme.secondaryColor};
  color: #fff;
  h2{
    color: #fff;
  }
`

export const StyledForm = styled.form`
width: 100%;
display: block;
line-height: 150%;

input:not([type="submit"]), select, textarea{
  display: block;
  width: 100%;
  line-height: 100%;
  padding: 15px 20px;
}

label{
  display: block;
  font-size: 1.5rem;
  font-family: ${props => props.theme.headingFontFamily};
  margin-bottom: 20px;
}

input:not([type="submit"]), textarea, select{
  border: 1px solid ${props => props.theme.secondaryColor};
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px;
  border-radius: 0;
}

.label{
  margin-bottom: 10px;
  font-weight: bold;
  &.required:after{
    content: "*";
    color: #ff4d44;
  }
}

`

const StyledSubmit = styled(Submit)`
width: auto;
display: inline-block;
margin: 0 auto;
color: #fff !important;
`

interface BaseFormProps {
  render: (handleChange: (event: any) => void) => void,
  title: string,
  action: string,
  name: string,
  stores?: string[],
}

declare global {
  interface Window {
    dataLayer: any
  }
}

export default class BaseForm extends React.Component<BaseFormProps> {

  constructor(props: BaseFormProps) {
    super(props)

    this.state = {}
  }

  public handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  public handleSubmit = (event: any) => {
    event.preventDefault()
    event.persist()

    fetch(this.props.action, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...this.state,
        "form-name": this.props.name,
      }),
    })
      .then((response) => response.json())
      .then(json => {
        if(json.success){
          window.dataLayer.push({
            "event": "formCompleted",
            "formName": this.props.name,
            "eventCallback": () => {
              navigate('/thanks')
            }
          })
          return;
          // return navigate('/thanks')
        } else {
          return new Promise((resolve, reject) => {
            reject('There was an error submitting the form.');
          });
        }
      })
      .catch(error => alert(error))
  }

  public render() {
    const { title, action, render, name } = this.props

    return (
      <React.Fragment>
        <StyledFormContainer style={{ paddingTop: 40, paddingBottom: 40 }} fluid>
          <GridRenderer colAtts={{ col: 12, sm: 8, smOffset: 2 }}>
            <StyledForm
              action={action}
              name={name}
              method="POST"
              onSubmit={this.handleSubmit}
            >
              <p style={{ display: "none" }}>
                <label>Don’t fill this out if you're human: <input onChange={this.handleChange} name="bot-field"/></label>
              </p>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>{title}</h2>
              </div>
              {render(this.handleChange)}
              <div style={{ textAlign: "center" }}>
                <Submit
                  style={{
                    width: "auto",
                    display: "inline-block",
                    margin: "0 auto",
                    color: "#fff",
                  }}
                  inputAtts={{ value: "Submit" }}
                  block={false}
                />
              </div>
            </StyledForm>
          </GridRenderer>
        </StyledFormContainer>
        <DownArrow/>
      </React.Fragment>
    )
  }
}
