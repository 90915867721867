import * as React from "react"
import { graphql } from "gatsby"

export default (props) => {
  return (
    <div style={{marginTop: -47}} dangerouslySetInnerHTML={{ __html: props.html }}/>
  )
}

export const query = graphql`
    fragment HTML on PRISMIC_PageBodyRaw_html{
        type
        primary{
            html
        }
    }
`