import * as React from "react"
import { graphql } from "gatsby"
import ProductCallout from "../../components/ProductCallout/ProductCallout"
import { prismicLinkMaker } from "../utils/linkResolver"

export default (props) => {
    const { 
        button_url, 
        button_text, 
        product_imageSharp, 
        product_image, 
        side_image, 
        side_imageSharp, 
        heading, 
        subtitle 
    } = props

    const link = prismicLinkMaker({button_url, button_text})

    return (
        <ProductCallout
            heading={heading}
            subtitle={subtitle}
            link={link}
            productImage={product_imageSharp && {alt: product_image?.alt, imageInfo: product_imageSharp}}
            sideImage={side_imageSharp && {alt: side_image?.alt, imageInfo: side_imageSharp}}
        />
    )
}

export const query = graphql`
    fragment ProductCallout on PRISMIC_PageBodyProduct_callout {
        type
        label
        primary {
            button_text
            button_url {
                ...Linkable
            }
            subtitle
            heading
            product_image
            product_imageSharp{
                childImageSharp{
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            side_image
            side_imageSharp{
                childImageSharp{
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`