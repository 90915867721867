import * as React from "react"
import Img from "../../components/Img/Img"
import {Col, Container, Row} from "../../components/Grid/Grid"
import { styled } from "../../theme/theme"
import {mb} from "../../utils/spacing"
import { graphql } from "gatsby"


const StyledContainer = styled(Container)`
`

export default (props) => {
  const { image, imageSharp } = props

  return (
    <StyledContainer style={{padding: 0}} fluid>
      <Row>
        <Col col={12}>
          <Img
            imageInfo={typeof imageSharp === 'object' ? imageSharp : { src: image.url}}
            alt={image.alt}
          />
        </Col>
      </Row>
    </StyledContainer>
  )
}

export const query = graphql`
    fragment Image on PRISMIC_PageBodyImage {
        type
        label
        primary {
            image
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 2880, quality: 80) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`