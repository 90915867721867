import * as React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver, prismicLinkMaker } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import ImageWithText from "../../components/ImageWithText/ImageWithText"
import GridRenderer from "../../components/GridRenderer/GridRenderer"
import uuidv4 from "../utils/uuidv4"
import { graphql } from "gatsby"
import { Col, Container, Row } from "../../components/Grid/Grid"
import { styled } from "../../theme/theme"
import DownArrow from "../../components/DownArrow"
import { pb } from "../../utils/spacing"

const StyledContainer = styled(Container)`
background-color: ${props => props.isDark ? props.theme.secondaryColor : "transparent"};
h1, h2, h3, p{
  color: ${props => props.isDark ? "#fff" : "initial"};
}
${props => props.isDark && `
${pb({default: 2, md: 6})}
`} 

`

export default (props) => {
  let cols = 4
  switch (props.cols) {
    case "1 Column":
      cols = {md: 12}
      break
    case "2 Column":
      cols = {md: 4, sm: 6, mdOffset: 3}
      break
    case "3 Column":
      cols = {md: 4}
      break
    case "4 Column":
      cols = {md: 3}
      break
    default:
      cols = {md: 4}
  }

  const isDark = props.style === "Dark Background"

  return (
    <React.Fragment>
      <StyledContainer fluid isDark={isDark}>
        {props.mainHeading &&
        <Row>
          <Col col={12} md={8} mdOffset={2} style={{ textAlign: "center", marginTop: "2rem" }}>
            {RichText.render(props.mainHeading, linkResolver, htmlSerializer)}
          </Col>
        </Row>
        }
        <GridRenderer
          colAtts={{ col: 12, ...cols }}
          colStyles={{ padding: 0 }}
          fluid={cols === 12}
        >
          {props.fields.map(field => {
              const {
                text,
                heading,
                button_text = "",
                image,
                imageSharp,
                image_position,
                button_url = null,
                // background_color = null,
              } = field

              const link = prismicLinkMaker({ button_url, button_text })

              const imageData = {
                imageInfo: imageSharp?.childImageSharp ? imageSharp : { src: image.url },
                alt: image.alt,
              }

              return (
                <ImageWithText
                  key={uuidv4()}
                  showBorder={!isDark && props.cols !== '2 Column'}
                  heading={heading.filter(item => item.text).length ?
                    RichText.render(
                      heading,
                      linkResolver,
                      htmlSerializer,
                    ) : null
                  }
                  text={text ? RichText.render(text, linkResolver, htmlSerializer) : null}
                  image={imageData}
                  link={link}
                  imagePosition={image_position}
                  isDark={isDark}
                  // backgroundColor={background_color}
                />
              )
            },
          )}
        </GridRenderer>
      </StyledContainer>
      {isDark && <DownArrow/>}
    </React.Fragment>
  )
}

export const query = graphql`
    fragment ImageWithText on PRISMIC_PageBodyImage_with_text {
        type
        label
        primary {
            cols
            mainHeading: heading
            style
        }
        fields{
            button_text
            button_url {
                ...Linkable
            }
            text
            heading
            image
            image_position
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`