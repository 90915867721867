import * as React from "react"
import { media } from "styled-bootstrap-grid"
import { mb, pb, py } from "../../utils/spacing"
import { styled } from "../../theme/theme"
import { Col, Container, Row } from "../Grid/Grid"
import Img, { ImageProps } from "../Img/Img"
import PreviewSafeBackgroundImage from "../PreviewSafeBackgroundImage"
import Link, { LinkProps } from "../Link/Link"
import TGILink from "../Link/Link"

interface CalloutProps {
  image?: null | ImageProps,
  heading?: string,
  link?: LinkProps, 
}

const StyledCallout = styled.div<any>`
  width: 100%;
  position: relative;
  display: flex;  
  flex-direction: row;
  align-items: center;
  ${mb(5)};
  ${mb({ md: 6 })};
  
  .banner{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.secondaryColor};
    padding: 16px;
    color: #fff;
    flex-direction: column;
    ${media.md`
      flex-direction: row;
      padding-top: 5%;
      padding-bottom: 5%;
    `}
  }
  
  .heading{
    font-family: ${props => props.theme.headingFontFamily};
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 130%;
    margin-bottom: 15px;
    ${mb({default: 2, md: 3})};
    
    ${media.sm`
      font-size: 40px;
    `}
  }
  
  p{
    line-height: 150%;
  }
  
  .img-wrapper{
    position: relative !important; 
    width: 100%;
    height: 200px;
    
    ${media.md`
      position: absolute !important;
      width: 50%;
      height: 120%;
    `}
    
    img{
      position: relative;
      width: auto;
      max-width: 100%;
      display: block;
      max-height: 350px;
      
      ${media.sm`
        max-width: 50%;
      `}
      
      ${media.md`
        max-width: 100%;
      `}
    }
  }
  
  .content{
    ${py({default: 2, md: 0})};
    ${media.md`
      padding-right: 3%;
      margin-bottom: -32px;
      max-width: 100%;
      align-self: flex-end;
      margin-left: auto;
      max-width: 45%;
    `}
  }
`

const StyledLink = styled(TGILink)`
  &.btn-outline{
        color: #fff;
        border: 1px solid #fff;
        
        &:hover{
          background-color: #fff;
          color: ${props => props.theme.secondaryColor};
        }
      }
  }
`

export default function Callout(props: CalloutProps) {
  const { image = null, heading = '', link = null } = props

  return (
    <StyledCallout className={"callout-wrapper"}>
      <div className={"banner"}>
        {image !== null &&
        <PreviewSafeBackgroundImage
          style={{backgroundSize: 'auto 100%', backgroundPosition: 'right center'}} 
          image={image} 
          className={"img-wrapper"} 
        />
        }
        <div className={"content"}>
          
          <div className={'heading'}>{heading}</div>
          {link !== null &&
          <StyledLink {...link} button={true} outline={true} />
          }
        </div>
      </div>
    </StyledCallout>
  )
} 