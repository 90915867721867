import * as React from 'react'
import { graphql } from "gatsby"
import Specs from "../../components/Specs/Specs"

export default (props) => {
  return (
    <Specs specs={props.fields} />
  )
}

export const query = graphql`
    fragment Specs on PRISMIC_PageBodySpecs {
        type
        label
        fields {
            key
            value
        }
    }
`

