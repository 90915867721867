import * as React from "react"
import { Col, Container, Row } from "../Grid/Grid"
import { styled } from "../../theme/theme"
import { mb, ml, pl, pt, px } from "../../utils/spacing"
import { ImageProps } from "../Img/Img"
import Link, { LinkProps } from "../Link/Link"
import { media } from "styled-bootstrap-grid"
import PreviewSafeBackgroundImage from "../PreviewSafeBackgroundImage"

export interface ImageWithTextProps {
  image: ImageProps,
  imagePosition?: "Top" | "Left" | "Right"
  text?: React.ReactElement,
  link?: LinkProps,
  heading?: React.ReactElement,
  backgroundColor?: string,
  isDark?: boolean,
  showBorder?: boolean,
}

const StyleContainer = styled(Container)<ImageWithTextProps>`
background-color: ${props => props["data-background-color"] ? props["data-background-color"] : "transparent"};
${props => props["data-background-color"] ? mb(5) + ";" : ""}
${props => props["data-background-color"] ? pt(4) + ";" : ""}
${props => props["data-image-position"] === "Top" ? mb({ default: 2, md: 0 }) : mb(0)};
`

const StyledImageWithText = styled(Container)<ImageWithTextProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  button, a{
    margin-top: ${props => props["data-image-position"] === "Top" ? "auto" : "2rem"};
  }
  
  .body-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${props => props["data-image-position"] !== "Top" && px({md: 4 }) + ";"}
  }
  
  .image{
    width: 100%;
    height: auto;
    ${props => props["data-image-position"] === "Top" && mb({ default: 1, md: 2 }) + ";"}
    ${media.xs`
      padding: 0;
      margin: 0;    
      margin-bottom: 2rem;
    `}
  }
  
  text-align: ${props => props["data-image-position"] === "Top" ? "center" : "left"};
  
  h2, h3, h4{
    margin-bottom: 0;
    span{
      border-bottom: none;
    }
  }
`

const StyledHeading = styled.div`
  
`

const StyledText = styled.div`
  padding-right: 1rem;
  text-align: ${props => props["data-image-position"] === "Top" ? "center" : "left"};
  margin-bottom: ${props => props["data-image-position"] === "Top" ? "42px" : "0"};
`

const StyledLink = styled.div`
  text-align: ${props => props["data-image-position"] === "Top" ? "center" : "left"}
  ${props => props["data-image-position"] === "Left" ? ml({ default: 0, md: 4 }) + ";" : ""}
`

const StyledTextWrap = styled.div`
`

const StyledBackgroundImage = styled(PreviewSafeBackgroundImage)`
border: ${props => props["showBorder"] ? "10px solid #fff" : "none"};
padding-bottom: 66.7%;
`


export default function ImageWithText(props: ImageWithTextProps) {
  const {
    link = null,
    image,
    text = null,
    heading = null,
    imagePosition = "Top",
    backgroundColor = null,
    isDark = false,
    showBorder = true,
  } = props

  const buttonStyles = isDark ? {
    backgroundColor: "#3A454C",
    border: "2px solid #858C90",
    fontWeight: "normal",
    display: "inline",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "12px 20px",
    color: "#fff",
  } : {}

  return (
    <StyleContainer
      data-background-color={backgroundColor}
      data-image-position={imagePosition}
      fluid
    >
      <Row>
        <StyledImageWithText data-image-position={imagePosition}>
          <Row style={{ marginLeft: 0, marginRight: 0, flex: 1 }}>
            <Col
              col={12}
              md={imagePosition === "Top" ? 12 : 6}
              order={1}
              mdOrder={imagePosition === "Right" ? 2 : 1}
              className={"image"}
            >
              {image &&
              <StyledBackgroundImage
                image={image}
                showBorder={showBorder}
              />
              }
            </Col>

            <Col
              className={"body-content"}
              col={12}
              md={imagePosition === "Top" ? 12 : 6}
              order={2}
              mdOrder={imagePosition === "Right" ? 1 : 2}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {heading &&
              <StyledHeading data-image-position={imagePosition}>{heading}</StyledHeading>
              }

              {text &&
              <StyledText data-image-position={imagePosition}>{text}</StyledText>
              }

              {link !== null &&
              <StyledLink data-image-position={imagePosition}>
                <Link
                  block={true}
                  outline={true}
                  button={true}
                  {...link}
                  style={buttonStyles}
                />
              </StyledLink>
              }
            </Col>
          </Row>
        </StyledImageWithText>
      </Row>
    </StyleContainer>
  )
}

ImageWithText.defaultProps = {
  image_position: "Top",
}