import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver, prismicLinkMaker } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { graphql } from 'gatsby';
import CalloutBg from "../../components/CalloutBg/CalloutBg"

export default (props) => {
  const {imageSharp, image, text, button_url, button_text} = props

  const imageData = image && {
    imageInfo: typeof imageSharp === 'object' ? imageSharp : {src: image.url},
    alt: image.alt,
  }
  
  const link = prismicLinkMaker({button_text, button_url});
  const richText = RichText.render(text, linkResolver, htmlSerializer)

  return (
    <CalloutBg text={richText} image={imageData} link={link} />
  )
}

export const query = graphql`
    fragment CalloutBg on PRISMIC_PageBodyCallout_with_bg {
        type
        label
        primary{
            text
            button_text
            button_url{
                ... Linkable
            }
            image
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 2880) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`
