import * as React from "react"
import { media } from "styled-bootstrap-grid"
import { mb } from "../../utils/spacing"
import { styled } from "../../theme/theme"
import { Col, Container, Row } from "../Grid/Grid"
import Img, { ImageProps } from "../Img/Img"
import uuidv4 from "../../prismic/utils/uuidv4"

interface CalloutProps {
  image?: null | ImageProps,
  points?: {main_text: string, overline_text: string}[],
}

const StyledCallout = styled.div<any>`
  width: 100%;
  display: flex;  
  flex-direction: column;
  justify-content: center;
  ${mb(5)};
  ${mb({ md: 6 })};
  
  .banner{
    width: 100%;
    background-color: ${props => props.theme.secondaryColor};
    display: block;
    color: #fff;
  }
  
  .heading{
    font-family: ${props => props.theme.headingFontFamily};
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 100%;
    margin-bottom: 15px;
    
    ${media.sm`
      font-size: 40px;
    `}
  }
  
  p{
    line-height: 150%;
  }
  
  .img-wrapper{
    position: relative;
    img{
      width: 100%;
    }
  }
  
  .image-inner{
    position: relative; 
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    
    ${media.md`
      max-width: auto;
      position: absolute;
      transform: translateY(-50%);
      left: 0;
      right: 0;
    `}
    
    img{
      position: relative;
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      display: block;
      
      ${media.sm`
        max-width: 50%;
      `}
      
      ${media.md`
        max-width: 100%;
      `}
    }
  }
  
  .content{
    //padding-top: 60px;
    //padding-bottom: 60px;
    margin-bottom: -32px;
  }
`

const StyledOverline = styled.div`
  text-transform: uppercase;
  font-family: ${props => props.theme.headingFontFamily};
  font-size: 1rem;
  line-height: 100%;
  margin-bottom: 8px;
`

const StyledPointText = styled.div`
  font-family: ${props => props.theme.headingFontFamily};
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 100%;
`

const StyledPoint = styled(Col)`
  ${mb(2)};
`

export default function KeyPoints(props: CalloutProps) {
  const { image = null, points = [] } = props

  return (
    <StyledCallout className={"callout-wrapper"}>
      <Container className={"banner"} fluid>
        <Container>
          <Row alignItems={"center"}>
            {image &&
            <Col col={12} sm={3} className={"img-wrapper"}>
              <div className={"image-inner"}>
                <Img {...image} />
              </div>
            </Col>
            }
            <Col col={12} sm={8} smOffset={1} className={"content"}>
              <Container>
                <Row>
                  {points.map(point => (
                    <StyledPoint key={uuidv4()} col={12} md={6}>
                      <StyledOverline>
                        {point.overline_text}
                      </StyledOverline>
                      <StyledPointText>
                        {point.main_text}
                      </StyledPointText>
                    </StyledPoint>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </StyledCallout>
  )
} 