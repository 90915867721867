import * as React from "react"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import PrismicImageWithText from "./PrismicImageWithText"

const staticQuery = graphql`
    query {
        prismic{
            allPages(tags: "How To / Resources", sortBy: sort_order_DESC) {
                edges {
                    cursor
                    node {
                        _meta {
                            id
                            uid
                        }
                        title
                        excerpt
                        slug_prefix
                        image: featured_image
                        imageSharp: featured_imageSharp{
                            childImageSharp{
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default ({number_of_articles = 3, richTextHeading = null}) => {
  
  return (
    <StaticQuery
      query={`${staticQuery}`}
      render={data => {
        const articles = data.prismic.allPages.edges.slice(0, number_of_articles === 'All' ? 9999 : number_of_articles);
        
        return (
          <PrismicImageWithText
            mainHeading={richTextHeading}
            style={"Normal"}
            cols={3}
            fields={articles.map(({node: article }) => ({
              text:[{
                type: "paragraph",
                text: article.excerpt,
                spans: []
              }],
              heading: [{
                type: "heading3",
                text: article.title,
                spans: []
              }],
              image: article.image,
              imageSharp: article.imageSharp,
              image_position: "Top",
              button_url: {
                _linkType: "Link.document",
                _meta: {
                  id: article._meta.id,
                  uid: article._meta.uid,
                  type: "page"
                },
                slug_prefix: article.slug_prefix,
              },
              button_text: 'Read More'
            }))}
          />
        )
      }}
    />
  )
}

// export const query = graphql`
//   fragment HowToGuides on PRISMIC_PageBodyHow_to_articles {
//       type
//       label
//       primary{
//           number_of_articles
//       }
//   }
// `