import * as React from "react"
import BaseForm from "./BaseForm"

export default () => (
  <BaseForm 
    title={'Contact Us'} 
    action={'https://usebasin.com/f/cce98b683322.json'} 
    name={'contact'}
    render={handleChange => (
      <React.Fragment>
        <div>
          <label>
            <div className={"label required"}>Name</div>
            <input type="text" name="name" onChange={handleChange} placeholder={'Name'} required />
          </label>
        </div>
        <div>
          <label>
            <div className={"label required"}>Phone Number</div>
            <input type="tel" name="phone" onChange={handleChange} placeholder={'(###) ###-###'} required />
          </label>
        </div>
        <div>
          <label>
            <div className={"label required"}>Email</div>
            <input type="email" name="email" onChange={handleChange} placeholder={'Email'} required />
          </label>
        </div>
        <div>
          <label>
            <div className={"label required"}>How can we help?</div>
            <textarea rows={10} name="message" onChange={handleChange} placeholder={'Message'} required />
          </label>
        </div>
      </React.Fragment>
    )}
  />
    
)