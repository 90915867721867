import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import Text from "../../components/Text/Text"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { graphql } from 'gatsby';
import { styled } from "../../theme/theme"

const StyledText = styled(Text)`
  p{
    line-height: 140% !important;
    font-size: 1.875rem !important;
    color: #1B2830;  
  }
`

export default (props) => {
  const {text} = props
  
  return (
    <StyledText alignment={'center'}>
      {RichText.render(text, linkResolver, htmlSerializer)}
    </StyledText>
  )
}

export const query = graphql`
    fragment TextCallout on PRISMIC_PageBodyText_callout {
        type
        label
        primary{
            text
        }
    }
`
