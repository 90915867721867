import * as React from 'react'
import { prismicLinkMaker } from "../utils/linkResolver"
import { graphql } from 'gatsby';
import Callout from "../../components/Callout/Callout"

export default (props) => {
  const {imageSharp, image, heading, button_text, button_url} = props

  const imageData = image && {
    imageInfo: typeof imageSharp === 'object' ? imageSharp : {src: image.url},
    alt: image.alt,
  }

  const link = prismicLinkMaker({button_url, button_text})

  return (
    <Callout link={link} heading={heading} image={imageData} />
  )
}

export const query = graphql`
    fragment Callout on PRISMIC_PageBodyCallout {
        type
        label
        primary{
            heading
            button_text
            button_url {
                ...Linkable
            }
            image
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`
