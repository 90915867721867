import * as React from "react"
import LinkResolver, { linkResolver } from "../utils/linkResolver"
import Link from "../../components/Link/Link"
import GridRenderer from "../../components/GridRenderer/GridRenderer"
import { graphql } from "gatsby"

export default (props) => {
  const {
    button_style = "Button Filled",
    button_url,
    button_text,
  } = props

  const getButtonStyle = (button_style) => {
    switch (button_style) {
      case "Button Outlined":
        return {
          outline: true,
          button: true,
        }

      case "Button Filled":
        return {
          button: true,
        }

      default:
        return {
          button: false,
        }
    }
  }

  return (
    <GridRenderer colStyles={{ justifyContent: "center" }}>
      <Link
        {...getButtonStyle(button_style)}
        to={LinkResolver.url(button_url, linkResolver)}
        linkType={button_url._linkType}
      >
        {button_text}
      </Link>
    </GridRenderer>
  )
}

export const query = graphql`
    fragment ButtonOrLink on PRISMIC_PageBodyButton_or_link{
        type
        primary{
            button_style
            button_text
            button_url {
                ...Linkable
            }
        }
    }
`