import * as React from "react"
import { styled } from "../../theme/theme"
import { Col, Container, Row } from "../Grid/Grid"
import { pl, py } from "../../utils/spacing"
import uuidv4 from "../../prismic/utils/uuidv4"

interface SpecsProps {
  specs?: { key: string, value: string }[],
}

const StyledInner = styled.div<SpecsProps>`
  ${py({ default: 2, sm: 5 })};
  ${pl({ default: 2, sm: 6 })};
`

const StyledRow = styled(Row)`
  ${py(2)};
  border-bottom: 1px solid #A3A0A0;
  
  h4{
    margin: 0;
  }
`

const Specs = ({ specs }: SpecsProps) => (
  <Container>
    <Row>
      <Col col={12} md={8} mdOffset={2}>
        {specs.map(({ key, value }) => (
          <StyledRow key={uuidv4()}>
            <Col col={12} md={6}>
              <h4>{key}</h4>
            </Col>

            <Col col={12} md={6}>
              <p>{value}</p>
            </Col>
          </StyledRow>
        ))}
      </Col>
    </Row>

  </Container>
)

export default Specs