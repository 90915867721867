import * as React from 'react';
import Helmet from "react-helmet"
import ComponentList from "./mappings"

interface Component {
    type: string,
    primary?: object,
    fields?: object,
    __typename: string,
}

interface BodyProps {
    node: {
        meta_title: string,
        meta_description: string,
        title: string,
        body: Component[],
        __typename: string,
    }
}

const Components = (type: string, ComponentList) => {
    if (typeof ComponentList[type] === "undefined") {
        return null
    }

    return ComponentList[type]
}

const Body = ({node}: BodyProps) => {
    let i = 0;
    const {meta_title, meta_description, title, body: components} = node

    let helmetProps = {title: meta_title ? meta_title : title}
    if (meta_title) {
        helmetProps['titleTemplate'] = '';
    }

    return (
        <React.Fragment>
            <Helmet {...helmetProps}>
                {meta_description &&
                <meta name="description" content={meta_description}/>
                }
            </Helmet>

            {components && components.map((comp) => {
                i++;

                const {fields = {}, primary = {}} = comp
                // Slices should always have a 'primary' or 'fields' key with it's associated data.
                if (!fields && !primary && process.env.NODE_ENV === "development") {
                    console.warn('No inline fragment has been provided for ' + comp.__typename)
                    return null;
                }

                const mappedComponent = Components(comp.type, ComponentList);

                // If we're in development, show an error that the component cannot be found
                if (mappedComponent === null && process.env.NODE_ENV === "development") {
                    console.warn('Component not found: The body renderer could not find a mapping for "' + comp.type + '". Please add it to mappings.tsx')

                    return <div>Could not find {comp.type}</div>
                }

                return React.createElement(mappedComponent, {
                    key: 'slice' + i, ...primary,
                    fields: fields
                })
            })}
        </React.Fragment>
    )
}

export default Body