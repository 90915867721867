import * as React from "react"
import { ReactElement } from "react"
import { ColProps as sbgColProps, ContainerProps, RowProps } from "styled-bootstrap-grid"
import {Col, Container, Row} from "../Grid/Grid"
import { styled } from "../../theme/theme"

export interface GridRendererProps extends ContainerProps {
  children: ReactElement | ReactElement[],
  colAtts?: sbgColProps,
  colStyles?: object,
  rowAtts?: RowProps,
  equalHeight?: boolean,
  fluid?: boolean,
  style?: React.StyleHTMLAttributes,
}

const StyledRow = styled(Row)`
justify-content: space-between;
`

export default function GridRenderer(props: GridRendererProps) {
  const { 
    children, 
    rowAtts = {}, 
    equalHeight = true, 
    colAtts = {}, 
    fluid = false, 
    style = {} 
  } = props
  
  let {colStyles} = props

  if (equalHeight) {
    colStyles = { ...colStyles, display: equalHeight ? "flex" : "block" }
  }
  
  const elements = React.Children.map(children, (child, i) => {
    
    let mdOffset = {};
    if(colAtts?.mdOffset){
      mdOffset = {mdOffset: i%2 !== 0 ? colAtts.mdOffset : 0}
    }
    
    return (
      <Col {...colAtts} {...mdOffset} style={colStyles}>
      {React.cloneElement(child)}
    </Col>
    )
  })

  return (
    <Container {...props} style={style} fluid={fluid}>
      <StyledRow {...rowAtts}>
        {elements}
      </StyledRow>
    </Container>
  )
} 