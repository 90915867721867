import * as React from "react"
import { styled } from "../../theme/theme"
import { Col, Container, Row } from "../Grid/Grid"
import Img, { ImageProps } from "../Img/Img"
import { media } from "styled-bootstrap-grid"
import { ButtonStyles } from "../Link/Link"
import DownArrow from "../DownArrow"
import { pb, pt } from "../../utils/spacing"

interface TextProps {
  alignment?: "left" | "center" | "right",
  children: React.ReactFragment,
  className?: string,
  isDark?: boolean,
  downArrow?: boolean,
}

const StyledCol = styled(Col)<TextProps>`
text-align: ${props => props["data-alignment"] ? props["data-alignment"] : "left"};
`

const StyledContainer = styled(Container)`
${props => props.isDark && `
  background-color: ${props.theme.secondaryColor};
  h1, h2, h3, p{
    color: #fff;
  }
  ${pb({default: 3, md: 6})}
  ${pt({default: 2, md: 4})}
`}

p{
  // When link is the first child of a paragraph, then we know we want it to be a button
 & > a{
  ${props => props.isDark ? ButtonStyles : null}
      background-color: #3A454C;
      border: 2px solid #858C90;
      font-size: 1.125rem;
      font-weight: normal;
      display: inline;
      margin-left: auto;
      margin-right: auto;
      padding: 12px 36px;
      color: #fff;
      
      &:hover{
        background-color: #3A454C;
      }
   }
}
`

const Text = ({ alignment, children, className, isDark = false, downArrow = false }: TextProps) => (
  <React.Fragment>
  <StyledContainer isDark={isDark} style={{ position: "relative" }} fluid className={className}>
    <Row>
    <Container>
      <Row style={{zIndex: 2, position: 'relative'}}>
        <StyledCol
          sm={12}
          md={8}
          mdOffset={2}
          data-alignment={alignment}
        >
          {children}
        </StyledCol>
      </Row>
    </Container>
    </Row>
  </StyledContainer>
  {downArrow &&
    <DownArrow light={!isDark}/>
  }
  </React.Fragment>
)

export default Text