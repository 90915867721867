import * as React from "react"
import { styled } from "../../theme/theme"
import { ButtonStyles } from "./Link"

export interface SubmitProps {
  outline?: boolean,
  block?: boolean,
  inputAtts?: any,
  className?: string,
  style?: object,
  onClick?: (e:any) => void,
  disabled?: boolean,
}

const StyledInput = styled.input<SubmitProps>`
${ButtonStyles}
`
export default function Submit(props: SubmitProps) {
  const { inputAtts = {}, className = '', onClick = () => null, disabled = false, style = {} } = props;
  
  return (<StyledInput type="submit" disabled={disabled} style={style} onClick={(e:any) => onClick(e)} className={className} {...inputAtts} />)
} 