import * as React from "react"
import ContactForm from "../../components/Forms/ContactForm"
import { graphql } from "gatsby"

export default (props) => {
  const { formType } = props

  let form = null
  switch (formType) {
    case "Contact":
      form = <ContactForm/>
      break
    default:
      form = null
  }
  return form
}

export const query = graphql`
  fragment Form on PRISMIC_PageBodyForm{
    type
    primary{
      formType: type
    }
  }
`