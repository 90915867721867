import * as React from "react"
import { graphql } from "gatsby"
import Testimonial from "../../components/Testimonial/Testimonial"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"

export default ({ fullName, personTitle, text }) => {

  return (
    <Testimonial 
      fullName={fullName} 
      personTitle={personTitle} 
      text={RichText.render(text, linkResolver, htmlSerializer)} 
    />
  )
}

export const query = graphql`
    fragment Testimonial on PRISMIC_PageBodyTestimonial {
        type
        label
        primary{
            fullName: full_name
            personTitle: person_title
            text
        }
    }
`
