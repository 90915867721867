import { graphql } from "gatsby"
import * as React from "react"
import Body from "../Body"
import Layout from "../Layout"
import { query as HeroFragment } from "../components/PrismicHero"
import { query as ProductCalloutFragment } from "../components/PrismicProductCallout"
import { query as TextFragment } from "../components/PrismicText"
import { query as SpacerFragment } from "../components/PrismicSpacer"
import { query as KeyPointsFragment } from "../components/PrismicKeyPoints"
import { query as BulletPointsFragment } from "../components/PrismicBulletPoints"
import { query as ImageFragment } from "../components/PrismicImage"
import { query as SpecsFragment } from "../components/PrismicSpecs"
import { query as CalloutFragment } from "../components/PrismicCallout"
import { query as ImageWithTextFragment } from "../components/PrismicImageWithText"
import { query as ButtonOrLinkFragment } from "../components/PrismicLink"
import { query as TextCalloutFragment } from "../components/PrismicTextCallout"
import { query as HTMLFragment } from "../components/PrismicHTML"
import { query as DataSheetsFragment } from "../components/PrismicDataSheet"
import { query as CalloutBgFragment } from "../components/PrismicCalloutBg"
import { query as TestimonialFragment } from "../components/PrismicTestimonial"
import { query as FormFragment } from "../components/PrismicForm"
import { query as BigTextHeroFragment } from "../components/PrismicBigTextHero"
// import { query as HowToGuidesFragment } from "../components/PrismicHowToGuides"
import { query as LinkableFragment } from "../utils/linkResolver"
import { Col, Container, Row } from "../../components/Grid/Grid"
import { styled } from "../../theme/theme"
import { mt } from "../../utils/spacing"

const StyledH1 = styled.h1`
position: relative;
${mt({default: 3, md: 5})};

.subtitle{
  color: #858C90;
  position: absolute;
  line-height: 0;
  font-size: 24px;
  right: 0;
  bottom: 0;
}
`

const Page = ({ data: { prismic: { allPages: { edges: pages } } } }) => {
  const page = pages.slice(0, 1).pop()
  if (!page) return null

  return (
    <Layout>
      {page.node._meta.uid !== "home" &&
      <Container>
        <Row>
          <Col col={12} style={{ textAlign: "center" }}>
            <StyledH1>
                <span>
                  {page.node.title}
              </span>
              {!!page.node._meta.tags.length &&
              <div className={"subtitle"}>{page.node._meta.tags[0]}</div>
              }
            </StyledH1>
          </Col>
        </Row>
      </Container>
      }
      <Body node={page.node}/>
    </Layout>
  )
}

export const query = graphql`
    query singlePage($uid: String){
        prismic {
            allPages(uid: $uid) {
                edges {
                    node {
                        __typename
                        _meta{
                            id
                            uid
                            type
                            tags
                        }
                        slug_prefix
                        meta_title
                        meta_description
                        title
                        body{
                            __typename
                            ... Hero
                            ... BigTextHero
                            ... ProductCallout
                            ... Text
                            ... Spacer
                            ... KeyPoints
                            ... BulletPoints
                            ... Image
                            ... Specs
                            ... Callout
                            ... ImageWithText
                            ... ButtonOrLink
                            ... TextCallout
                            ... HTML
                            ... DataSheets
                            ... CalloutBg
                            ... Testimonial
                            ... Form
                            ... on PRISMIC_PageBodyHow_to_articles {
                                type
                                label
                                primary{
                                    number_of_articles
                                    richTextHeading: heading
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

Page.query = query
Page.fragments = [
  HeroFragment,
  ProductCalloutFragment,
  TextFragment,
  SpacerFragment,
  KeyPointsFragment,
  BulletPointsFragment,
  ImageFragment,
  SpecsFragment,
  CalloutFragment,
  ImageWithTextFragment,
  ButtonOrLinkFragment,
  TextCalloutFragment,
  HTMLFragment,
  DataSheetsFragment,
  CalloutBgFragment,
  TestimonialFragment,
  LinkableFragment,
  FormFragment,
  BigTextHeroFragment,
]

export default Page