import PrismicHero from "./components/PrismicHero"
import PrismicText from "./components/PrismicText";
import PrismicSpacer from "./components/PrismicSpacer";
import PrismicProductCallout from "./components/PrismicProductCallout";
import PrismicKeyPoints from "./components/PrismicKeyPoints";
import PrismicBulletPoints from "./components/PrismicBulletPoints";
import PrismicImage from "./components/PrismicImage";
import PrismicSpecs from "./components/PrismicSpecs";
import PrismicCallout from "./components/PrismicCallout";
import PrismicImageWithText from "./components/PrismicImageWithText";
import PrismicLink from "./components/PrismicLink";
import PrismicTextCallout from "./components/PrismicTextCallout";
import PrismicHTML from "./components/PrismicHTML";
import PrismicDataSheet from "./components/PrismicDataSheet";
import PrismicCalloutBg from "./components/PrismicCalloutBg";
import PrismicTestimonial from "./components/PrismicTestimonial";
import PrismicForm from "./components/PrismicForm";
import PrismicBigTextHero from "./components/PrismicBigTextHero";
import PrismicHowToGuides from "./components/PrismicHowToGuides";


export default {
    hero: PrismicHero,
    text: PrismicText,
    spacer: PrismicSpacer,
    product_callout: PrismicProductCallout,
    key_points: PrismicKeyPoints,
    bullet_points: PrismicBulletPoints,
    image: PrismicImage,
    specs: PrismicSpecs,
    callout: PrismicCallout,
    image_with_text: PrismicImageWithText,
    button_or_link: PrismicLink,
    text_callout: PrismicTextCallout,
    raw_html: PrismicHTML,
    data_sheets: PrismicDataSheet,
    callout_with_bg: PrismicCalloutBg,
    testimonial: PrismicTestimonial,
    form: PrismicForm,
    big_text_hero: PrismicBigTextHero,
    how_to_articles: PrismicHowToGuides,
}