import * as React from 'react'
import { graphql } from "gatsby";
import BigTextHero from "../../components/BigTextHero/BigTextHero"

export default (props) => {
  const { imageSharp, image, line1 = '', line2 = '', line3 = '' } = props

  const imageData = image && {
    imageInfo: typeof imageSharp === 'object' ? imageSharp : {src: image.url},
    alt: image.alt,
  }
  
  return (
    <BigTextHero
      image={imageData}
      line1={line1}
      line2={line2}
      line3={line3}
    />
  )
}

export const query = graphql`
    fragment BigTextHero on PRISMIC_PageBodyBig_text_hero {
        type
        label
        primary {
            line1
            line2
            line3
            image
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 2880) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`