import * as React from 'react'
import { graphql } from "gatsby"
import KeyPoints from "../../components/KeyPoints/KeyPoints"

export default (props) => {
  const {imageSharp, image, fields} = props

  const imageData = image && {
    imageInfo: typeof imageSharp === 'object' ? imageSharp : {src: image.url},
    alt: image.alt,
  }

  return (
    <KeyPoints image={imageData} points={fields} />
  )
}

export const query = graphql`
    fragment KeyPoints on PRISMIC_PageBodyKey_points {
        type
        label
        primary {
            image
            imageSharp{
                childImageSharp{
                    fluid(maxWidth: 574) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        fields {
            main_text
            overline_text
        }
    }
`

